// Next

// MUI
import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  CircularProgress,
  Pagination,
  Stack
} from '@mui/material';
import { useState } from 'react';

// Local
import { OPTIONS_PROFESSIONAL_POSITION } from '../../src/config';
import { ENUM_CITIES, ENUM_STATES } from '../../src/constants';

import Dropdown from "../Reusable/Dropdown";
import DropdownAutoComplete from '../Reusable/DropdownAutoComplete';
import { log } from 'utils';

export default function JobSearchHeader({
  onSearch,
  onPageChange,
  loading = false,
  filters, setFilters,
  totalPages, page,
}) {
  const [states, setStates] = useState(ENUM_STATES);
  const [cities, setCities] = useState([]);

  function updateFilters({ position, city, state }) {
    setFilters({
      position: position !== undefined ? position : filters.position,
      state: state !== undefined ? state : filters.state,
      city: city !== undefined ? city : filters.city,
    })
  }

  const updatePage = (event, value) => {
    // only if page changes
    if (page != value) {
      onPageChange(value);
    }
  };

  return (
    <Stack
      direction={{ xs: "column", md: "column" }}
      alignItems="center"
      justifyContent="space-between"
      spacing={2}
      mb={2}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={1}
        sx={{ width: { xs: '100%', md: '100%' } }}
      >
        <Dropdown
          x="position"
          label='Job'
          disabled={loading}
          sx={{ width: '50%' }}
          options={OPTIONS_PROFESSIONAL_POSITION}
          extraTopOptions={{ "": "All Professions" }}
          value={filters.position ?? ""}
          onChange={e => { updateFilters({ position: e.target.value }); onSearch(); }}
        />
        <Dropdown
          x="state"
          label='State'
          disabled={loading}
          sx={{ width: '50%' }}
          options={states}
          extraTopOptions={{ "": "All States" }}
          value={filters.state ?? ""}
          onChange={e => { setCities(ENUM_CITIES[e.target.value] || []); updateFilters({ state: e.target.value }); }}
        />
        <Dropdown
          x="city"
          label='City'
          disabled={loading}
          sx={{ width: '50%' }}
          options={cities}
          extraTopOptions={{ "": "All Cities" }}
          value={filters.city ?? ""}
          onChange={e => {  updateFilters({ city: e.target.value}); }}
        />
      </Stack>

      <Stack
        direction={{ xs: "column-reverse", lg: "row" }}
        alignItems="center"
        justifyContent="center"
        spacing={3}
        sx={{ width: "100%" }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={{ xs: "center", lg: "flex-start" }}

          sx={{ width: { xs: "100%", lg: "50%" } }}
        >
          {totalPages >= 1 &&
            <Pagination shape="rounded" size="large" count={totalPages} page={page} onChange={updatePage} />
          }

        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          sx={{ width: { xs: "100%", lg: "50%" } }}
        >
          <Button
            onClick={onSearch}
            disabled={loading}
            variant="contained"
            startIcon={loading ? <CircularProgress color="white" size={15} /> : <SearchIcon />}>
            Search Jobs
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}