// Next
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from "react";
import AppContext from "../../src/AppContext";

// MUI
import PostAddIcon from '@mui/icons-material/PostAdd';
import {
  Grid,
  Typography
} from '@mui/material';

// Local
import { URL, ENUM_USERTYPE } from '../../src/config';
import { api_post, cookieGet, log } from "../../src/utils";
import IconLink from "../Reusable/IconLink";
import JobSearchHeader from "./JobSearchHeader";
import ViewJobPostComponent from "./ViewJobPost";

export default function SearchJobsPosts({ posterId = null, preFetch = false, page_size = null, small = false, hideHeader = false }) {
  const router = useRouter();
  const { user, setUser } = useContext(AppContext);

  // status
  const [criteriaChanged, setCriteriaChanged] = useState(false); // causes date refetch when true
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState("");
  const [serverResponse, setServerResponse] = useState("");
  const [preFetchDone, setPreFetchDone] = useState(false);

  // input
  const [filters, setFilters] = useState({
    position: null,
    city: null,
    state: null,
  });
  const [page, setPage] = useState(1)

  // output
  const [jobPosts, setJobPosts] = useState([])
  const [totalPages, setTotalPages] = useState(0)

  // Logic (data fetching)
  useEffect(() => {
    // Wait until user info is loaded before showing page
    if (!(user)) {
      return;
    }

    if (criteriaChanged || (preFetch && !preFetchDone)) {
      // TODO, after we figure out how to get user position
      // const defaultJobInHeader = preFetch && user.type == ENUM_USERTYPE.PROFESSIONAL ? 
      fetchJobPosts();
      setPreFetchDone(true)
    }
  }, [user, criteriaChanged])

  function onSearch() {
    setPage(1)
    setCriteriaChanged(true)
  }

  function onPageChange(value) {
    setPage(value)
    setCriteriaChanged(true)
  }

  function fetchJobPosts() {
    setLoading(true)
    setServerError("")

    // Create form
    var form = {}
    form.jwt = cookieGet("jwt")
    form.poster_id = posterId
    form.position = filters.position
    form.state = filters.state
    form.city = filters.city
    form.page = page
    form.page_size = page_size

    // Connect to API
    api_post(URL.API.GET_JOB_POSTS, form)
      .then((res) => {
        if (res.data.success === true) {
          setJobPosts(res.data.job_posts)
          setTotalPages(res.data.total_pages)
          setServerError("")

          if (res.data.job_posts.length == 0) {
            setServerResponse("No jobs matching your criteria, please try different criteria.")
          }
        }
        else {
          setServerError("Error during operation.")
        }
      })
      .catch(function (error) {
        log(error)
        setServerError("Error during operation.")
      })
      .then(() => { // finally
        setLoading(false)
        setCriteriaChanged(false)
      })
  }

  // UI
  return (
    <>
      {
        (user) &&
        <Grid container
          alignContent={'center'}
          justifyContent={'center'}
          spacing={2}
        >
          {
            !hideHeader &&
            <Grid item
              xs={12}
              md={8}
            >
              <JobSearchHeader onSearch={onSearch} onPageChange={onPageChange} loading={loading} filters={filters} setFilters={setFilters} page={page} totalPages={totalPages} />
            </Grid>
          }

          {
            jobPosts.map((jobPost) => (
              <Grid item
                xs={12}
                md={8}
                key={jobPost.id}>
                <ViewJobPostComponent jobPost={jobPost} listing></ViewJobPostComponent>
              </Grid>)
            )
          }

          {
            !small && jobPosts.length == 0 &&
            <Grid item
              xs={12}
              md={8}
            >
              <Typography
                variant="h5">
                Select your criteria and search dental jobs in India.
              </Typography>
            </Grid>
          }

          {
            !small && user.type === ENUM_USERTYPE.EMPLOYER &&
            <Grid item
              xs={12}
              md={8}
            >
              <IconLink href={URL.WEB.POST_JOB} variant="h5" icon={<PostAddIcon />}>
                Post a job
              </IconLink>
            </Grid>
          }
        </Grid>
      }
    </>
  );
}
