import { TextField, MenuItem } from "@mui/material";

export default function Dropdown({ x: uniqueId, label, options, extraTopOptions, ...props }) {
    // Convert options array into a dict
    if (Array.isArray(options)) {
        options = options.reduce((index, value) => { index[value] = value; return index; }, {});
    }

    // add the extra options to the top of the list
    if(extraTopOptions && Object.keys(extraTopOptions).length > 0) {
        options = Object.assign({}, extraTopOptions, options)
    }
    
    return (
        <TextField
            select
            label={label}
            id={uniqueId}
            name={uniqueId}
            key={uniqueId}
            {...props}
        >
            {
                Object.keys(options).map((k, index) => (
                    <MenuItem value={k} key={index}>{options[k]}</MenuItem>
                ))
            }
        </TextField>
    );
}